import { render } from "react-dom";
import App from "./App";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resources from "./translation.js";

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: "en",
});

render(<App />, document.querySelector("#content"));
