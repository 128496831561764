const translation = {
  en: {
    translation: {
      title: "Suiview",
      subtitle1: "A Web-based Application that Enables Users to ",
      subtitle2: "Practice Wind Instrument Performance",
      changeLanguage: "Change Language",
      japanese: "Japanese",
      english: "English",
      aboutSuiview: "About Suiview",
      aboutSuiview1_1: "This application helps beginners who are ",
      aboutSuiview1_2: "just starting to practice wind instruments to ",
      aboutSuiview1_3: "produce stable sounds.",
      aboutSuiview2_1:
        "You can easily record and visually check the stability of  ",
      aboutSuiview2_2: "your performance from the three perspectives of ",
      aboutSuiview2_3: "pitch, intensity, and timbre.",
      aboutSuiview3_1:
        "Also, from the teacher mode, you can see the performance ",
      aboutSuiview3_2: "data of the registered user and add comments.",
      aboutSuiview3_3:
        "Therefore, it is possible to receive evaluation from others.",
      studentMode: "Student mode",
      studentMode1_1: "When the user records the performance, ",
      studentMode1_2: "you can know how stable the performance is ",
      studentMode1_3:
        "from the three viewpoints of pitch, intensity, and timbre",
      studentMode1_4: " from the score and graph.",
      studentMode2_1: "Also, by accumulating performance data, ",
      studentMode2_2: "You can see the transition of stability.",
      teacherMode: "Teacher mode",
      teacherMode1_1: "In teacher mode,",
      teacherMode1_2: " you can see and comment on ",
      teacherMode1_3: "student performances and graphs.",
      teacherMode1_4: "Students can see the comments you write.",
      teacherMode1_5: "Help your students',and watch over their growth.",
      clickHere: "Click here",
      recommendedSmartphone: "Recommended for use on smartphones",
      recommendedPC: "Recommended for use on PC/tablet",
      home: "Home",
      record: "Record",
      detail: "Detail",
      comparePeformances: "Compare",
      publications: "Publications",
      publications1:
        "Please cite the following articles for referring to Suiview.",
      team: "College of Humanities and Sciences, Nihon University",
      watanabe: "Misato Watanabe",
      numabe: "Kei Numabe",
      onoue: "Yosuke Onoue",
      uemura: "Aiko Uemora",
      kitahara: "Tetsuro Kitahara",
      lab1: "Onoue Lab.",
      lab2: "Kitahara Lab.",
    },
  },
  ja: {
    translation: {
      title: "吹view",
      subtitle1: "管楽器を対象とした基礎的演奏技向上のための",
      subtitle2: "音響特徴可視化アプリの試作",
      changeLanguage: "言語設定",
      japanese: "日本語",
      english: "英語",
      aboutSuiview: "吹Viewとは",
      aboutSuiview1_1: " 管楽器の練習を始めたばかりの初心者が",
      aboutSuiview1_2: "安定した音を出せるようにするための",
      aboutSuiview1_3: "お手伝いをするアプリです。",
      aboutSuiview2_1: "管楽器を練習したいユーザが気軽に録音し,",
      aboutSuiview2_2:
        "高さ・強さ・音色の3観点からどの程度演奏が安定しているかを",
      aboutSuiview2_3: "視覚的に確かめることができます。",
      aboutSuiview3_1: "また、先生モードからは登録したユーザの",
      aboutSuiview3_2: "演奏データを見てコメントをつけることができるため",
      aboutSuiview3_3: "他者からの評価をもらうこともできます。",
      studentMode: "生徒モード",
      studentMode1_1: "ユーザーが演奏を録音すると、",
      studentMode1_2: "高さ・強さ・音色の3観点から",
      studentMode1_3: "どの程度演奏が安定しているかを",
      studentMode1_4: "点数とグラフから知ることができます。",
      studentMode2_1: "また、演奏データを蓄積していくことで",
      studentMode2_2: "安定度の推移がわかります。",
      teacherMode: "先生モード",
      teacherMode1_1: "先生モードでは生徒が演奏した",
      teacherMode1_2: "音源とグラフを見て",
      teacherMode1_3: "コメントを付けることができます。",
      teacherMode1_4: "付けたコメントは生徒が見ることができます。",
      teacherMode1_5: "生徒をサポートし、成長を見守りましょう。",
      clickHere: "こちらをクリック",
      recommendedSmartphone: "スマートフォン推奨",
      recommendedPC: "PC・タブレット推奨",
      home: "ホーム",
      record: "録音",
      detail: "曲詳細",
      comparePeformances: "演奏データ比較",
      publications: "参考文献",
      publications1: "吹Viewの引用は以下の文献をご利用ください。",
      team: "日本大学文理学部",
      watanabe: "渡邉みさと",
      numabe: "沼部恵",
      onoue: "尾上洋介",
      uemura: "植村あい子",
      kitahara: "北原鉄朗",
      lab1: "尾上研究室",
      lab2: "北原研究室",
    },
  },
};

export default translation;
